<template>
	<div class="wf">

		<el-dialog :close-on-click-modal="false" title="系统方案信息" :visible.sync="dialogVisible" width="1000px">

			<div class="wf">
				<el-form ref="newsForm" :model="newsForm" label-width="120px" :rules="rules">
					<el-row class="wf">
						<el-col :span="12">
							<el-form-item label="方案标题" prop="title">
								<el-input v-model="newsForm.title" placeholder="请填写方案标题" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="发布时间" prop="subTime">
								<el-date-picker v-model="newsForm.subTime" type="datetime" placeholder="选择发布时间"
									style="width:100%;" value-format="yyyy-MM-dd HH:mm:ss" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="发送对象" prop="tags">
								<div class="wf">
									<el-input placeholder="请输入用户姓名/手机号/身份证号码" v-model="patientKey"
										class="input-with-select" @change="getPatients"></el-input>
								</div>
								<el-checkbox-group v-model="newsForm.tags">
									<el-checkbox v-for="patient in patientList"
										:label="patient.serial+'-'+patient.name+'-'+patient.phone+'-'+patient.member">
										{{patient.name}}{{patient.phone}}
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
						</el-col>
						<el-col :span=" 24">
							<el-form-item label="方案简介" prop="digest">
								<el-input v-model="newsForm.digest" type="textarea" :rows="6" placeholder="请填写素材简介" />
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label-width="0">
								<div class="pdding10" style="border-top: 1px dashed #DEDEDE;">
									<div class="wf lineh40 bold">方案内容</div>
									<VueUEditor @ready="ueditorReady" style="min-height:600px;background: #F1F1F1;">
									</VueUEditor>
								</div>
							</el-form-item>
						</el-col>

					</el-row>

				</el-form>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishNews">保存</el-button>
			</span>
		</el-dialog>

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	export default {
		name: 'News',
		components: {
			VueUEditor
		},
		data() {
			return {
				dialogVisible: false,
				imgUploadUrl: this.$apis.component.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				loading: false,
				tableLocale: {
					emptyText: '暂无数据'
				},
				patientList: [],
				newsTypeList: [],
				newsClassificationList: [],
				cover: '',
				patientKey: '',
				searchForm: {
					page: 1,
					pageSize: 100
				},
				newsForm: {
					cover: "",
					content: "",
					tags: []
				},
				superSerial: "",
				rules: {
					title: [{
							required: true,
							message: '请填写方案标题',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 2 到 100个字符',
							trigger: 'blur'
						}
					],
					classification: [{
						required: true,
						message: '请选择所在分类',
						trigger: 'blur'
					}],
					price: [{
						required: true,
						message: '请填写价格',
						trigger: 'blur'
					}],
					origin: [{
							required: true,
							message: '请填写素材来源',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 30,
							message: '长度在 2 到 30个字符',
							trigger: 'blur'
						}
					],
					digest: [{
							required: true,
							message: '请填写素材简介',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 200,
							message: '长度在 2 到 200个字符',
							trigger: 'blur'
						}
					],
					subTime: [{
						required: true,
						message: '请选择发布时间',
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			beforePicUpload(file) {

				console.log("图片类型", file.type)

				const isJPG = file.type === 'image/jpeg';
				const isJPEG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';

				const isLt1M = file.size / 1024 / 1024 < 1;

				if (!(isJPG || isJPEG || isPNG)) {
					this.$message.error('上传图片只能是 JPG、PNG、JPEG 格式!');
				}

				if (!isLt1M) {
					this.$message.error('上传图片大小不能超过 1MB!');
				}
				return (isJPG || isJPEG || isPNG) && isLt1M;
			},
			handleCoverSuccess(res, file) {
				console.log("文件上传", res)
				if (res.files.length > 0)
					this.newsForm.cover = res.files[0].uploadUrl;
			},
			ueditorReady(ueditor) {
				let _this = this
				this.ueditor = ueditor;
				ueditor.addListener("contentChange", () => {
					_this.newsForm.content = ueditor.getContent();
				});
				if (this.newsForm.content != undefined)
					ueditor.setContent(this.newsForm.content)
			},
			menuSelect(item) {
				this.searchForm.superSerial = item;
				this.searchForm.page = 1;
				this.searchForm.name = "";
				this.getClassificationList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.cover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.cover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}
			},
			newItem() {
				this.newsForm = {
					price: 0,
					cover: "",
					subTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
					classification: "",
					tags: []
				};
				console.log("dsadas", this.ueditor)
				if (this.ueditor != undefined)
					this.ueditor.setContent("");
				this.dialogVisible = true;
			},
			showItem(newsForm) {
				this.dialogVisible = true;
				this.newsForm = this.$http.cleanData(newsForm);
				var claszzes = newsForm.classification.split("-");
				this.superSerial = claszzes[0]
			},
			getNewsTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.newsTypeList, {
					pageSize: 100
				}, false, res => {
					this.newsTypeList = res.data;
					if (this.superSerial == "")
						this.superSerial = this.newsTypeList[0].serial;
					this.getClassificationList();
				});
			},
			getClassificationList() {
				this.newsForm.classification = "";
				this.$http.request(this, this.$apis.material.newsClassificationList, {
					superSerial: this.searchForm.superSerial,
					pageSize: 100
				}, false, res => {
					console.log("子分类", res)
					this.newsClassificationList = res.data;
				});
			},
			publishNews() {
				this.$refs['newsForm'].validate((valid) => {
					if (valid) {
						var newsForm = Object.assign({}, this.newsForm);
						var claszzes = newsForm.classification.split("-");
						newsForm.classification = claszzes[claszzes.length - 1];
						newsForm.id = "";
						newsForm.other = 3;
						newsForm.tags = JSON.stringify(newsForm.tags);
						newsForm.updateTime = "";

						this.$http.request(this, this.$apis.material.noticePublish, newsForm, true, (res) => {
							let data = res.data;
							if (data != null) {
								data.tags = JSON.parse(data.tags);
								this.$emit("addNews", data)
							}
							this.dialogVisible = false;
						});
					}
				});
			},
			getPatients() {
				this.$http.request(this, this.$apis.tools.patientList, {
					name: this.patientKey,
					pageSize: 20
				}, false, res => {
					this.searchForm.total = res.count;
					res.data.forEach(item => {
						item.label = JSON.parse(item.label);
						item.region = item.region != "" && item.region != null && item.region !=
							undefined ? JSON.parse(
								item.region) : ["", ","];
						item.property = JSON.parse(item.property);
						item.medical = JSON.parse(item.medical);
						item.surgery = JSON.parse(item.surgery);
						item.age = item.age == 0 ? "" : item.age;
					})
					this.patientList = res.data;
					console.log(res.data);
				});
			},
		},
		watch: {
			dialogVisible(newValue, oldValue) {
				if (newValue) {
					this.getPatients();
				}
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
</style>


<style>
</style>
