<template>
	<div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">运营管理</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">建议方案</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3ac87e;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form inline class="ant-advanced-search-form">
					<el-form-item label="标题">
						<el-input placeholder="请填写方案标题" size="small" clearable v-model="searchForm.name"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i> 查询
						</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="frame_content mgtop10" style="min-height: 600px;">

			<div class="wf lineh40">
				<el-button type="danger" size="small" @click="batchRemove"><i class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newItem"><i class="fa fa-plus"></i>新增方案</el-button>
			</div>

			<el-table class="mgtop10" :data="newsList" :stripe="true" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="标题" prop="title" />
				<el-table-column label="发送对象" prop="tags" width="200px" align="center">
					<template slot-scope="scope">
						<el-tag v-for="tag in scope.row.tags" type="success" size="small">
							{{tag.split("-")[1]}}{{tag.split("-")[2]}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="浏览量" prop="reading" width="80px" align="center" />
				<el-table-column label="发布时间" prop="subTime" align="center" width="160px" />
				<el-table-column label="发送状态" prop="notice" width="80" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.notice==0" type="info" size="small">待发送</el-tag>
						<el-tag v-else type="success" size="small">已发送</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" key="action" align="center" width="200px">
					<template slot-scope="scope">
						<el-row :gutter="10">
							<el-col :span="12">
								<el-button :disabled="scope.row.notice==1" style="width: 100%;" type="success"
									size="small" @click="noticeNews(scope.$index)">发送方案</el-button>
							</el-col>
							<el-col :span="12">
								<el-button style="width: 100%;" type="info" size="small"
									@click="copyNews(scope.$index)">复制方案</el-button>
							</el-col>
							<el-col :span="12" class="mgtop10">
								<el-button style="width: 100%;" type="primary" size="small"
									@click="showNews(scope.$index)">编辑</el-button>
							</el-col>
							<el-col :span="12" class="mgtop10">
								<el-button style="width: 100%;" type="danger" size="small"
									@click="removeNews(scope.$index)">删除</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf overflow bgffffff mgtop10">
				<div class="pdding20">
					<el-pagination v-show="newsList.length>0" @size-change="pageSizeChange" @current-change="pageChange"
						:current-page="searchForm.page" :page-sizes="[10, 20, 30, 40]" :page-size="searchForm.pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="searchForm.total" background
						class="fr"></el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<fragment-news-option ref="newsOption" @addNews="addNews"></fragment-news-option>
		<!-- 弹出层 end-->


	</div>
</template>

<script>
	import FragmentNewsOption from '@/components/fragment/news/FragmentNoticePlanOption.vue'
	export default {
		name: 'News',
		components: {
			FragmentNewsOption
		},
		data() {
			return {
				dialogVisible: false,
				imgUploadUrl: this.$http.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				dialogFormVisible: false,
				searchForm: {
					type: 3,
					page: 1,
					pageSize: 20,
					total: 0,
					classification: ""
				},
				multipleSelection: [],
				newsTypeList: [],
				newsClassificationList: [],
				newsClassificationCacheList: [],
				newsList: [],
				mcover: '',
				newsForm: {},
				loading: false,
				imageUrl: '',
			}
		},
		methods: {
			addNews(data) {
				this.newsList.unshift(data);
			},
			menuSelect(item) {
				//获取当前类别的分类
				this.curTypeSerial = item;
				this.searchForm.page = 1;
				this.searchForm.superSerial = item;
				this.searchForm.classification = "";
				this.getClassificationList()
				this.getNewsList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.mcover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.mcover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}

			},
			cancel() {
				this.dialogFormVisible = false
			},
			doSearch() {
				//查询
				this.searchForm.page = 1;
				this.getNewsList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getNewsList();
			},
			pageSizeChange(pageSize) {
				this.searchForm.pageSize = pageSize;
				this.getNewsList();
			},
			newItem() {
				this.$refs["newsOption"].newItem();
			},
			getNewsTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.newsTypeList, {
					pageSize: 100
				}, false, res => {
					this.newsTypeList = res.data;
					//获取当前类别的分类
					this.curTypeName = this.newsTypeList[0].name;
					this.curTypeSerial = this.newsTypeList[0].serial;
					this.searchForm.superSerial = this.curTypeSerial;
				});
			},
			getClassificationList() {
				var searchForm = {
					superSerial: this.searchForm.superSerial,
					pageSize: 100
				};
				this.$http.request(this, this.$apis.material.newsClassificationList, searchForm, false, res => {
					this.newsClassificationList = res.data;
				});
			},
			getNewsList() {
				this.$http.request(this, this.$apis.material.noticeList, this.searchForm, false, res => {
					res.data.forEach(item => {
						item.tags = JSON.parse(item.tags)
					})
					this.newsList = res.data;
					this.searchForm.total = res.count;
				});
			},
			showNews(index) {
				this.newsForm = this.newsList[index];
				this.$refs["newsOption"].showItem(this.newsForm);
			},
			removeNews(index) {
				console.log(index, this.newsList[index])
				var that = this;
				var news = this.newsList[index];
				this.$confirm('确定要删除方案[' + news.title + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.newsRemove.replace("{serial}", news.serial);
					this.$http.request(this, url, {}, true, res => {
						this.newsList.splice(index, 1);
					});
				});
			},
			noticeNews(index) {
				var that = this;
				var news = this.newsList[index];
				this.$confirm('确定要发送方案[' + news.title + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.newsNotice.replace("{serial}", news.serial);
					this.$http.request(this, url, {}, true, res => {
						news.notice = res.data;
					});
				});
			},
			copyNews(index) {
				var that = this;
				var news = this.newsList[index];
				this.$confirm('确定要复制方案[' + news.title + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.newsNoticeCopy.replace("{serial}", news.serial);
					this.$http.request(this, url, {}, true, res => {
						if (res.code == 0) {
							var item = res.data;
							item.tags = JSON.parse(item.tags)
							this.newsList.unshift(item);
						}
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的方案 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.material.newsBatchRemove, {
						serials: serials
					}, true, res => {
						this.newsList = this.newsList.filter(news => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == news.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			}
		},
		created() {
			this.getNewsTypeCacheList();
			this.getNewsList();
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}
</style>
